@bootstraplessdir:"node_modules/bootstrap/less";

@import "fonts/tbfont";

@import "bootstrap/variables";
@import "@{bootstraplessdir}/mixins";

@import "utility";

@import "components/modals";

.pnlWebCallApp {
    position: relative;

    @import "web-common/less/box-sizing";
    @import "@{bootstraplessdir}/forms";
    @import "web-common/less/bootstrap/buttons";

    @import "@{bootstraplessdir}/alerts";

    @import "forms";

    @import "components/alerts";
    @import "components/button-groups";
    @import "components/button-toolbars";

    @import "webCallApp";
}
